<template>
  <div class="flex flex-col items-center justify-center min-h-screen">
    <h1 class="text-2xl mb-6">{{ t('join') }}</h1>

    <p class="text-lg mb-4">{{ t('inviteCode') }}</p>
    <div ref="qrCodeRef" class="mb-6"></div>

    <button class="mb-4 text-blue-600 text-lg" @click="shareQrCode">
      <v-icon
        icon="mdi mdi-share-variant-outline"
        :size="30"
      />
    </button>
    <div class="text-lg">
      {{ t('shareLinkText') }}
      <button class="font-bold text-lg text-blue-600" @click="shareLink">
        {{ t('here') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type QRCodeStyling from 'qr-code-styling'
import { useToast } from 'vue-toastification'
const toast = useToast()
const { t } = useI18n()
const { url } = useDomainHost()
const { $qrCodeStyling } = useNuxtApp()
const qrCodeRef = ref<HTMLElement | null>(null)
const { data } = useAuth()
const qrData = `${url}/auth/register?invitation_code=${data.value?.code}`
const options = {
  width: 300,
  height: 300,
  type: 'svg',
  data: qrData,
  dotsOptions: {
    color: '#000',
    type: 'rounded'
  },
  backgroundOptions: {
    color: '#fff'
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 10
  }
}

onMounted(() => {
  const qrCodeStyling: QRCodeStyling = $qrCodeStyling(options)
  qrCodeStyling.append(qrCodeRef.value)
  qrCodeRef.value!.firstChild!.setAttribute('viewBox', '0 0 300 300')
})

function shareQrCode() {
  try {
    const svgElement = qrCodeRef.value?.querySelector('svg')

    if (svgElement) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')!

      const width = 300
      const height = 300
      canvas.width = width
      canvas.height = height

      const svgData = new XMLSerializer().serializeToString(svgElement)
      const svgBlob = new Blob([svgData], {
        type: 'image/svg+xml;charset=utf-8'
      })
      const svgUrl = URL.createObjectURL(svgBlob)

      const img = new Image()
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height)

        canvas.toBlob(
          async (blob) => {
            if (blob) {
              if (navigator.share) {
                await navigator.share({
                  title: t('join'),
                  text: t('shareQrCodeText'),
                  files: [
                    new File([blob], 'qrcode.jpg', { type: 'image/jpeg' })
                  ]
                })
              } else {
                toast.error(t('sharingNotSupported'), { timeout: 1000 })
              }
            }

            URL.revokeObjectURL(svgUrl)
          },
          'image/jpeg',
          1.0
        )
      }

      img.src = svgUrl
    }
  } catch (error) {
    toast.error(t('shareError'), { timeout: 1000 })
  }
}
async function shareLink() {
  try {
    if (navigator.share) {
      const response = await fetch('/images/mevcut.jpg')
      const blob = await response.blob()

      await navigator.share({
        title: t('join'),
        text: `${t('shareQrCodeLink')} ${qrData}`,
        url: qrData,
        files: [
          new File([blob], 'mevcut.jpg', { type: 'image/jpeg' })
        ]
      })
    }
  } catch (error) {
    toast.error(t('sharingNotSupported'), { timeout: 1000 })
  }
}

useHead({
  title: t('invite_friends'),
  link: [useSeoCanonical(url + '/my-account/invite-friends')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('invite_friends'), url + '/my-account/invite-friends')
    ])
  ]
})
</script>
<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
